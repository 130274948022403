import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"

const Page404 = () => {
  return (
    <Layout>
      <section className="section-boxes section-boxes--secondary p-4">
        <div className="shell">
          <h1>Oh dear, the page you have requested is not available</h1>
          <p>
            Please navigate to the <Link to="/">home page.</Link>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Page404;